<template>
  <div id="Hostlist">
    <!-- =========  HOSTS TABLE  ========================================================================================================================================================================================================================== -->
    <!-- <p>{{ Hostlist }}</p> -->
    <vs-table
      @dblSelection="doubleSelection"
      v-model="selected"
      :data="Hostlist"
    >
      <template slot="thead">
        <vs-th>شماره هاست</vs-th>
        <vs-th>نوع هاست</vs-th>
        <vs-th>وضعیت</vs-th>
        <vs-th>اسم دامنه</vs-th>
        <vs-th>هزینه ماهانه</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].hostplan">
            {{ data[indextr].host_user }}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{ `${data[indextr].plan_title}-${data[indextr].plan_type_text}` }}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{ data[indextr].host_status_text }}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{ data[indextr].domain_name }}
          </vs-td>
          <vs-td :data="data[indextr]">
            {{ data[indextr].user_current_pay_month }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- <p>{{ selected }}</p> -->
  </div>
</template>
<script>
export default {
  props: {
    userId: { required: true },
  },
  data() {
    return {
      Hostlist: [],
      selected: "",
    };
  },
  watch: {
    selected() {
      this.$emit("selected", this.selected);
    },
  },
  methods: {
    getHostslist() {
      this.$http
        .get(
          `/admin/host/lists?user_id=${this.userId || this.$route.query.id}`
        )
        .then((res) => {
          this.Hostlist = res.data.params.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    doubleSelection() {
      this.$router.push(`/user?id=${this.selected.user_id}`);
    },
  },
  created() {
    this.getHostslist();
  },
};
</script>