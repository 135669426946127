<template>
    <section class="w-full">
      <vx-card>
        <vs-tabs alignment="fixed">
          <vs-tab label="Table">
            <div class="con-tab-ejemplo mt-5">
              <vs-table stripe :data="logDataPaginate">
                <template dir="ltr" slot="header">
                  <div class="flex justify-between w-full">
                    <h3 class="m-5 ">
                      Logs
                    </h3>
                    <div>
                      <vs-button class="mr-5" @click="filterPop = !filterPop"> فیلتر لاگ</vs-button>
                      <vs-button @click="popAddLog = !popAddLog">لاگ جدید</vs-button>
                      <vs-popup class="holamundo"  title="افزودن لاگ جدید    " :active.sync="popAddLog">
                        <p>
                          توجه داشته باشید که تمام موارد به درستی وارد شود
                        </p>
                        <div class="flex flex-col">
                          <div class="centerx labelx mt-5 flex ">
                            <vs-select class="m-1  w-6/12" v-model="select1" label="سرویس">
                              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in options1" />
                            </vs-select>
                            <vs-input label="product - scale" class="m-1 w-6/12" placeholder="محصول یا عملیات خاص" v-model="product"/>
                          </div>
                          <div class="centerx labelx mt-5 flex">
                            <vs-input label="شناسه کاربر" class="m-1 w-6/12" placeholder="شناسه کاربر را وارد کنید" v-model="logOwner"/>
                            <vs-input style="width: 100%" label="message" class="m-1 w-6/12" placeholder="پیام لاگ" v-model="userMessage"/>
                          </div>
                          <div  class="centerx labelx mt-5">
                            <vs-textarea style="background-color: #10163a" label="توضیح مورد نظر را وارد نمایید  " v-model="description" />
                          </div>
                        </div>
                        <vs-divider position="center">Meta</vs-divider>
                        <div class="flex flex-col">
                          <div class="centerx labelx mt-5 flex ">
                            <vs-input label="title" class="m-1 w-6/12" placeholder="عنوان  " v-model="title"/>
                            <vs-input label="userID" class="m-1 w-6/12" placeholder="آیدی کاربر  " v-model="userID"/>
                          </div>
                          <div class="mt-5">
                            <p>میزان رضایت کاربر</p>
                            <vs-slider color="danger" text-fixed="درجه رضایت کاربر" ticks step=10 v-model="user_degree"/>
                          </div>
                        </div>
                      <vs-button @click="createLog" class="w-full rounded-lg mt-5 mb-5"> ایجاد لاک </vs-button>
                      </vs-popup>
                    </div>
                  </div>
                </template>
                <template slot="thead">
                  <vs-th>
                    user_id
                  </vs-th>
                  <vs-th>
                    service
                  </vs-th>
                  <vs-th>
                    product | scale
                  </vs-th>
                  <vs-th>
                    action
                  </vs-th>
                  <vs-th>
                    message
                  </vs-th>
                  <vs-th>
                    description
                  </vs-th>
                  <vs-th>
                    meta
                  </vs-th>
                  <vs-th>
                    time
                  </vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td :data="data[indextr].user_id">
                      {{data[indextr].user_id}}
                    </vs-td>
                    <vs-td :data="data[indextr].service">
                      {{data[indextr].service}}
                    </vs-td>
                    <vs-td :data="data[indextr].product">
                      {{data[indextr].product}}
                    </vs-td>
                    <vs-td :data="data[indextr].action">
                      {{data[indextr].action}}
                    </vs-td>
                    <vs-td :data="data[indextr].message">
                      {{data[indextr].message}}
                    </vs-td>
                    <vs-td :data="data[indextr].description">
                      {{data[indextr].description}}
                    </vs-td>
                    <vs-td :data="data[indextr].meta">
                      <div class="p-2" style="background-color: black">
                        {{ JSON.stringify(data[indextr].meta) }}
                      </div>
                    </vs-td>
                    <vs-td :data="data[indextr].created_at">
                      {{data[indextr].created_at}}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <h1>{{ countPage }}</h1>
              <vs-pagination
                class="my-5"
                :total="countPage"
                v-model="pageNumber"
              ></vs-pagination>
            </div>
          </vs-tab>
          <vs-tab label="Logs">
            <div class="con-tab-ejemplo mt-5">
              <div  class="flex justify-around items-center m-2">
                <div>
                  تعداد لاگ ها : {{ logs.length }}
                </div>
                <div>
                  <vs-button @click="filterPop = !filterPop"> فیلتر لاگ</vs-button>
                </div>
                <div>
                  <vs-tooltip text="صعوری / نزولی  ">
                  <feather-icon icon="ChevronsUpIcon" />
                  </vs-tooltip>
                </div>
              </div>
              <br />
              <div dir="ltr" style="background-color: black; height: 50rem; overflow: scroll">
                <div v-for="(item, index) in logs" :key="index" class=" p-3">
                  <div @click="handleShowdes(item._id)">
                    <span class="terminal-font" style="color: grey">{{ index }}</span>
                    <span class="ml-2 terminal-font">{{ item.user_id + ':' + item.product + ' ' + 'action:' + item.action + ' ' + 'message:' + item.message + ' ' + 'meta:' + item.meta.map((des) => ( JSON.stringify(des) )) }}</span>
                    <span class="ml-2 terminal-font">| {{ item.created_at }}</span>
                  </div>
                  <div v-if="showDes && item._id === id">
                    <p style="color: grey" class="ml-4 text-sm terminal-font"> > {{ item.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </vx-card>
      <vs-popup class="holamundo"  title="فیلتر لاگ " :active.sync="filterPop">
        <div>
          <div class="centerx labelx mt-5 flex ">
            <vs-input label="شناسه کاربر" class="m-1 w-6/12" placeholder="شناسه کاربر را وارد کنید" v-model="logOwner"/>
            <vs-select class="m-1  w-6/12" v-model="select1" label="سرویس">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in options1" />
            </vs-select>
          </div>
          <div class="mt-5">
            <date-picker-fa v-model="date" class="input" locale="fa" placeholder="بازه زمانی خود را انتخاب کنید"  format="YYYY-MM-DD"/>
          </div>
        </div>
        <div class="mt-5 flex justify-end text-left">
          <vs-button @click="searchLogPaginate">فیلتر</vs-button>
        </div>
      </vs-popup>
    </section>
</template>

<script>
export default {
  name: 'Logs',
  props: {
    user_id : {required: false, default: null}
  },
  data () {
    return {
      logs: [],
      id: null,
      showDes: false,
      date: '',
      popAddLog: false,
      userMessage: '',
      logOwner: '',
      value2:10,
      service: '',
      product: '',
      countPage: 1,
      lastpage: '',
      pageNumber: 1,
      filterPop: false,
      action: `/sales/team/report`,
      title: '',
      creator: '',
      userID: '',
      description: '',
      user_degree: '',
      select1: 'Auth',
      logDataPaginate: [],
      options1:[
        {text:'سرور',value:'Server'},
        {text:'هاست',value:'Host'},
        {text:'خدمات',value:'Service'},
        {text:'یادداشت',value:'Memo'},
        {text:'تیکت',value:'Ticket'},
        {text:'گزارش',value:'Report'},
        {text:'فعالیت',value: 'Activity'},
        {text:'لاگین',value: 'Auth'},
      ],
    }
  },
  methods: {
    handleShowdes (item) {
      this.id = item
      this.showDes = !this.showDes
    },
    loadlogsPaginate () {
      this.$http(`/admin/LogList/all/?page=${this.pageNumber}`).then((res) => {
        const data = res.data.params
        this.countPage = data.countPage
        this.lastpage = res.data.params.last_page;
        this.logDataPaginate = data.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    loadlogsPaginateBased_userID () {
      this.$http(`/admin/LogList/${this.$route.query.id}/?page=${this.pageNumber}`).then((res) => {
        const data = res.data.params
        this.countPage = data.countPage
        this.lastpage = res.data.params.last_page;
        this.logDataPaginate = data.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    loadlogs () {
      this.$http.get('/admin/userlogs').then((res) => {
        this.logs = res.data.params.reverse()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    loadlogsBasedUser_id (user_id) {
      this.$http.get(`/admin/userlogs/show/${user_id}`).then((res) => {
        this.logs = res.data.params.reverse()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    searchLogPaginate () {
      this.$http.get(`/admin/userlogs/search/${this.logOwner}/${this.select1}/2022-02-10/`).then((res) => {
        const data = res.data.params
        this.countPage = data.countPage
        this.lastpage = res.data.params.last_page;
        this.logDataPaginate = data.data
        this.filterPop = false
        this.$vs.notify({
          color: "success",
          text: 'جستجو لاگ انجام شد ',
        });
      }).catch((err) => {
        console.log('err', err)
      })
    },
    createLog () {
      const meta = {
        title : this.title,
        creator: 2647,
        userID: parseInt(this.userID),
        userDegree: this.user_degree
      }
      const LogData = {
        user_id: parseInt(this.logOwner),
        service: this.select1,
        product: this.product,
        description: this.description,
        action: this.action,
        message: this.userMessage,
        meta : meta
      }
      console.log('serv log', LogData)
      this.$http.post('admin/userlogs/create/', {LogData}).then(res => {
          console.log('res logger', res)
        this.$vs.notify({
          color: "success",
          text: 'لاگ شما ایجاد شد ',
        });
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        console.log('err logger', err.response)
      })
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  watch : {
    pageNumber () {
      this.loadlogsPaginate()
    }
  },
  mounted () {
    if (this.user_id) {
      this.loadlogsBasedUser_id(this.user_id)
      this.logOwner = this.user_id
    } else {
      this.loadlogs()
    }
    if (this.$route.query.id) {
      this.loadlogsPaginateBased_userID()
    }
    else {
      this.loadlogsPaginate()
    }

    this.userMessage = this.activeUserInfo.name + ' ' + 'لاگ را ایجاد کرده'
    this.user_id = this.activeUserInfo.id
  }
}
</script>

<style scoped>

</style>
