var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vx-card',[_c('div',[_c('div',{staticClass:"flex justify-between my-5"},[_c('h3',[_vm._v("نوتیف ها")]),_c('vs-button',{on:{"click":function($event){_vm.notifpopup = !_vm.notifpopup}}},[_vm._v("نوتیف جدید")])],1),_c('div',[_c('vs-table',{staticClass:"hidescrollbar",attrs:{"data":_vm.notiflist},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].title}},[_vm._v(" "+_vm._s(data[indextr].title)+" ")]),_c('vs-td',{staticClass:"w-1/2",domProps:{"innerHTML":_vm._s(data[indextr].description)}}),_c('vs-td',[_c('vs-button',{attrs:{"radius":"","size":"small","icon":"delete","color":"danger"},on:{"click":function($event){return _vm.deleteNotification(data[indextr].id)}}})],1)],1)})}}])},[_c('div',{staticClass:"mar flex justify-center"},[_c('p',[_vm._v("test")])]),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" title ")]),_c('vs-th',[_vm._v(" description ")]),_c('vs-th')],1)],2)],1)])]),_c('vs-prompt',{attrs:{"active":_vm.notifpopup},on:{"accept":function($event){_vm.storeNotification({
        receiver_id: _vm.userID,
        title: _vm.notifTitle,
        description_html: _vm.notifDescription,
      });
      _vm.notifTitle = '';
      _vm.notifDescription = '';},"cancel":function($event){_vm.notifTitle = '';
      _vm.notifDescription = '';},"update:active":function($event){_vm.notifpopup=$event}}},[_c('vs-input',{staticClass:"my-5",attrs:{"label-placeholder":"عنوان"},model:{value:(_vm.notifTitle),callback:function ($$v) {_vm.notifTitle=$$v},expression:"notifTitle"}}),_c('quill-editor',{staticClass:"border-solid rounded my-5",attrs:{"options":_vm.editor_options},model:{value:(_vm.notifDescription),callback:function ($$v) {_vm.notifDescription=$$v},expression:"notifDescription"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }