<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap">
        <div class="w-full flex flex-wrap">
          <div class="w-full flex flex-wrap justify-between">
            <h3>یادداشت ها</h3>
            <vs-button @click="createNewNotePopup = true"
              >یادداشت جدید</vs-button
            >
          </div>
          <vs-divider></vs-divider>
        </div>
        <div
          class="hidescrollbar w-full"
          style="height: 304px; overflow: scroll; overflow-x: hidden"
        >
          <span
            class="flex flex-wrap justify-between"
            :key="index"
            v-for="(tr, index) in notelist"
          >
            <vs-row>
              <vs-col vs-w="11" vs-sm="11" vs-xs="12">
                <p class="font-semibold">{{ tr.title }}</p>
                <p>{{ tr.text }}</p>
              </vs-col>
              <vs-col vs-w="1" vs-sm="1" vs-xs="12">
                <vs-button
                  @click="
                    note_id = tr.id;
                    deleteNote();
                  "
                  color="danger"
                  radius
                  icon="delete"
                ></vs-button>
              </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
          </span>
        </div>
        <!-- <vs-table :data="notelist">
              <template slot="thead"> </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr]">
                    {{ data[indextr].title }}
                  </vs-td>
                  <vs-td :data="data[indextr]">
                    {{ data[indextr].description }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table> -->
      </div>
    </vx-card>
    <vs-popup title="یادداشت جدید" :active.sync="createNewNotePopup">
      <vs-input
        class="my-5"
        v-model="newNoteTitle"
        placeholder="Title"
      ></vs-input>
      <!-- <quill-editor class="mb-4" v-model="text" :options="editorOption" /> -->
      <vs-textarea
        class="my-5"
        v-model="newNoteText"
        placeholder="Text"
      ></vs-textarea>
      <!-- <p>{{ newNoteAttachment }}</p> -->

      <!-- ========================================================================================================================================================== -->
      <div>
        <!-- <input
          type="file"
          @change="this.newNoteAttachment = this.$refs.file.files[0]"
          ref="file"
        /> -->
        <input
          type="file"
          text="Attachments"
          ref="fileUpload"
          :show-upload-button="false"
        />
      </div>
      <!-- ========================================================================================================================================================== -->
      <vs-button
        @click="
          storeNote();
          createNewNotePopup = false;
          listAllNotes();
          data = {};
        "
        >افزودن</vs-button
      >
    </vs-popup>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    quillEditor,
  },
  props: {
    user_id: { required: true },
  },
  data() {
    return {
      notelist: [],
      // POPUP =======================
      createNewNotePopup: false,
      // POPUP =======================
      // NEW =======================
      newNoteTitle: "",
      newNoteText: "",
      newNoteAttachment: null, //  this.$refs.fileUpload.files[0]
      // NEW =======================
      // DELETE NOTE ===============
      note_id: "",
      // DELETE NOTE ===============
    };
  },
  methods: {
    storeNote() {
      const data = {
        title: this.newNoteTitle,
        text: this.newNoteText,
        user_id: this.user_id,
        file: this.$refs.fileUpload.files[0],
      };
      // console.log(data);
      let form_data = null;
      if (data.file) {
        form_data = new FormData();

        for (const key in data) {
          form_data.append(key, data[key]);
        }
      }
      this.$http
        .post(`admin/usermemos/store`, form_data || data, {
          headers: form_data ? { "Content-Type": "multipart/form-data" } : null,
        })
        .then((res) => {
          this.$vs.notify({ text: res.data.message, color: "success" });
          this.listAllNotes();
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
          // console.log(err);
        });
    },
    listAllNotes() {
      this.notelist = [];
      this.$http
        .get(`/admin/usermemos/${this.user_id}`)
        .then((res) => {
          this.notelist = res.data.params;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteNote() {
      this.$http
        .delete(`/admin/usermemos/${this.note_id}`)
        .then((res) => {
          this.$vs.notify({ text: res.data.message, color: "success" });
          this.listAllNotes();
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
  },
  created() {
    this.listAllNotes();
  },
};
</script>
<style scoped>
.hidescrollbar::-webkit-scrollbar {
  display: none;
}
</style>