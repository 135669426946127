<template>
  <div>
    <vs-row>
      <vs-col vs-w="9" vs-sm="12" vs-xs="12">
        <Userdata
          :adminUser="isAdmin"
          @refreshdata="getUserInfo"
          :userInfo="userInfo"
          :userID="userID"
        ></Userdata>
      </vs-col>
      <vs-col vs-w="0.3"></vs-col>
      <vs-col vs-w="2.7" vs-sm="12 my-6" vs-xs="12 my-1">
        <Userdetail
          :ticketlist="ticketlist.length"
          :notelist="notelist.length"
          :notiflist="notiflist.length"
          :postpaiddata="postpaiddata"
          @refreshdata="getUserInfo"
          :userInfo="userInfo"
          :userID="userID"
        ></Userdetail>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="6" vs-sm="12 my-5">
        <Usernote :user_id="userID"></Usernote>
      </vs-col>
      <vs-col vs-w="0.3"></vs-col>
      <vs-col vs-w="5.7" vs-sm="12 my-5" vs-xs="12">
        <Usernotification
          :notiflist="notiflist"
          @refreshnotif="listAllNotif"
          :userInfo="userInfo"
          :userID="userID"
        ></Usernotification>
      </vs-col>
    </vs-row>
    <vs-row class="my-5">
      <vs-col vs-w="12"
        ><vx-card
          ><div>
            <h3 class="my-2">تیکت های کاربر</h3>
            <vs-table
              v-model="selectedticket"
              @dblSelection="ticketchatpage(selectedticket)"
              :data="ticketlist"
            >
              <template slot="thead">
                <vs-th> شماره تیکت</vs-th>
                <vs-th> عنوان</vs-th>
                <vs-th> بخش </vs-th>
                <vs-th> کارشناس پاسخ دهنده </vs-th>
                <vs-th> آخرین پاسخ </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :state="
                    tr.last_side_update === 2 || !tr.agent_fullname
                      ? 'success'
                      : ''
                  "
                  :data="tr"
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                >
                  <vs-td :data="data[indextr].id"> {{ tr.id }} </vs-td>
                  {{ data[indextr].updated_at }}
                  <vs-td :data="data[indextr].updated_at"> </vs-td>
                  <vs-td :data="data[indextr].category_name">
                    {{ data[indextr].category_name }}
                  </vs-td>
                  <vs-td :data="data[indextr].updated_at">
                    {{ data[indextr].agent_fullname }}</vs-td
                  >
                  <vs-td :data="data[indextr].updated_at">
                    {{ data[indextr].updated_at }}</vs-td
                  >
                </vs-tr>
              </template>
            </vs-table>
          </div></vx-card
        ></vs-col
      >
    </vs-row>
    <vs-row>
      <vs-col class="my-5" vs-w="12">
        <vx-card>
          <div>
            <h3>Log و رویداد ها</h3>
            <Logs :user_id="userID" />
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
    <!-- ============================================================================================================================================================ -->
  </div>
</template>

<script>
import Userdata from "./Components/UserData.vue";
import Userdetail from "./Components/UserDetails.vue";
import Usernotification from "./Components/UserNotification.vue";
import Usernote from "./Components/UserNote.vue";
import Logs from '../../../views/Logs/index';
export default {
  components: {
    Logs,
    Userdata,
    Userdetail,
    Usernotification,
    Usernote,
  },
  watch: {
    userInfo() {
      this.userInfo["email"].includes("@haiocloud.com") ||
      this.importantuserids.includes(this.userID)
        ? (this.isAdmin = true)
        : (this.isAdmin = false);
    },
    notificationpopup() {
      if (!this.notificationpopup) {
        this.notifTitleL = "";
        this.notifDescription = "";
      }
    },
  },
  data() {
    return {
      depositlist: [],
      ticketlist: [],
      notiflist: [],
      changephoneto: null,
      factorDescription: "",
      tax: "",
      toDate: "",
      fromDate: "",
      factorPrompt: false,
      chargeDescrip: "",
      subject: "",
      selectPart: "",
      selectProduct: "",
      productOptions: [
        { text: "بخش پشتیبانی", value: "1" },
        { text: "بخش فروش", value: "2" },
      ],
      unitOptions: [
        { text: "HaioMail", value: "1" },
        { text: "HaioCloud", value: "2" },
        { text: "Monitoring", value: "3" },
      ],
      message: "",
      reason: "",
      password: "",
      charge: 0,
      reducecharge: 0,
      reducechargeDescrip: "",
      reducetag: null,
      userID: "",
      userInfo: "",
      postpaiddata: "",
      reducetaglist: [
        { text: "Devops", value: "devops" },
        { text: "Sysadmin", value: "sysadmin" },
        { text: "Lloudmigration", value: "cloudmigration" },
        { text: "Vira", value: "vira" },
        { text: "Containo", value: "containo" },
        { text: "Didban", value: "didban" },
        { text: "Mail", value: "mail" },
        { text: "Haiocloud", value: "haiocloud" },
        { text: "Vps", value: "vps" },
      ],
      //========= popups ===================
      activePrompt: false,
      postpaidpopup: false,
      notificationpopup: false,
      changephonepopup: false,
      giftscorepopup: false,
      //========= popups ===================
      //========= GIFT SCORE ===============
      giftscoreAmount: "",
      giftscoreDescription: "",
      //========= GIFT SCORE ================
      //=========== notification ============
      notifTitle: "",
      notifDescription: "",
      //=========== notification ============

      selectedticket: "",

      //================= FAKE DATA =====================
      notelist: [
        {
          title: "Title goes here",
          description:
            "Lorem ipsum dolor sit amet, consectetur \n adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          title: "Title goes here",
          description:
            "Lorem ipsum dolor sit amet, consectetur \n adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          title: "Title goes here",
          description:
            "Lorem ipsum dolor sit amet, consectetur \n adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          title: "Title goes here",
          description:
            "Lorem ipsum dolor sit amet, consectetur \n adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          title: "Title goes here",
          description:
            "Lorem ipsum dolor sit amet, consectetur \n adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          title: "Title goes here",
          description:
            "Lorem ipsum dolor sit amet, consectetur \n adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
      ],
      //================= FAKE DATA =====================
      //========ELSE=======
      importantuserids: [
        "1",
        "2",
        "2315",
        "2316",
        "2333",
        "2352",
        "2365",
        "2383",
        "2698",
        "2402",
        "2404",
        "2419",
        "2465",
        "2474",
        "2568",
        "2683",
        "2684",
      ],
      isAdmin: false,
      //========ELSE=======
    };
  },
  computed: {
    windowsWidth() {
      return parseInt(window.innerWidth) >= 900;
    },
  },
  methods: {
    getUserInfo() {
      this.$http
        .get(`/admin/user/${this.userID}`)
        .then((res) => {
          // console.log("***\n", res);
          this.userInfo = res.data.params.data[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //============================Billing & invoice===================================================
    Getdepositlist() {
      // console.log(this.userID);
      this.$http
        .get(`/admin/billing/pay/deposit/transaction?user_id=${this.userID}`)
        .then((res) => {
          // this.$vs.notify({ title: res.data.message });
          this.depositlist = res.data.params.data;
          // console.log(this.depositlist);
        })
        .catch((err) => {
          this.$vs.notify({ title: err.response.data.message });
        })
        .finally(() => {
          this.getUserInfo();
        });
    },
    //============================Billing & invoice===================================================
    refreshReduceCharge() {
      this.$http
        .post("admin/billing/wallet/refresh", { user_id: this.userID })
        .then((res) => {
          this.$vs.notify({
            color: "success",
            text: res.data.message,
          });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    ticketchatpage(tr) {
      this.$router.push(`/ticket/${tr.id}`);
    },
    //=====LISTING METHODS====
    listAllTicket() {
      this.$http
        .get(`/admin/ticket/get/customer?user_id=${this.userID}`)
        .then((res) => {
          this.ticketlist = res.data.params.data;
          // console.log(this.ticketlist);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listAllNotif() {
      this.$http
        .get(`/admin/notifications/?receiver_id=${this.userID}`)
        .then((res) => {
          this.notiflist = res.data.params.data;
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=====LISTING METHODS====
  },
  created() {
    this.userID = this.$route.query.id;
    this.getUserInfo();
    this.Getdepositlist();
    this.listAllTicket();
    this.listAllNotif();
  },
};
</script>
