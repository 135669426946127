<template>
  <div>
    <vx-card
      ><div>
        <div class="flex justify-between my-5">
          <h3>نوتیف ها</h3>
          <vs-button @click="notifpopup = !notifpopup">نوتیف جدید</vs-button>
        </div>
        <div>
          <vs-table class="hidescrollbar" :data="notiflist">
            <div class="mar flex justify-center">
              <p>test</p>
            </div>
            <template slot="thead">
              <vs-th> title </vs-th>
              <vs-th> description </vs-th>
              <vs-th></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].title">
                  {{ data[indextr].title }}
                </vs-td>
                <vs-td class="w-1/2" v-html="data[indextr].description">
                </vs-td>
                <vs-td>
                  <vs-button
                    radius
                    size="small"
                    icon="delete"
                    @click="deleteNotification(data[indextr].id)"
                    color="danger"
                  ></vs-button>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </vx-card>

    <vs-prompt
      @accept="
        storeNotification({
          receiver_id: userID,
          title: notifTitle,
          description_html: notifDescription,
        });
        notifTitle = '';
        notifDescription = '';
      "
      @cancel="
        notifTitle = '';
        notifDescription = '';
      "
      :active.sync="notifpopup"
    >
      <vs-input
        class="my-5"
        label-placeholder="عنوان"
        v-model="notifTitle"
      ></vs-input>
      <!-- <vs-textarea
        label="متن مورد نظر"
        class="my-5"
        v-model="notifDescription"
      ></vs-textarea> -->
      <quill-editor
        class="border-solid rounded my-5"
        v-model="notifDescription"
        :options="editor_options"
      />
    </vs-prompt>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    quillEditor,
  },
  props: {
    userID: { required: true },
    userInfo: { required: true },
    notiflist: { required: true },
  },
  data() {
    return {
      notifTitle: "",
      notifDescription: "",
      // NOTIFICATION========================================================================================================================================================================================
      notifpopup: false,
      // NOTIFICATION========================================================================================================================================================================================
      editor_options: this.$store.getters.text_editor_options,
    };
  },
  methods: {
    storeNotification(data) {
      // const data = {
      //   receiver_id: 2365,
      //   title: "testing notification",
      //   description: "everything you want"
      // };
      this.$http
        .post("admin/notification/store", data)
        .then((res) => {
          this.$vs.notify({ text: res.data.message, color: "success" });
          this.$emit("refreshnotif");
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    deleteNotification(id) {
      this.$http
        .delete(`admin/notification/${id}`)
        .then((res) => {
          this.$vs.notify({ text: res.data.message, color: "success" });
          this.$emit("refreshnotif");
        })
        .catch((err) => {
          console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
  },
  created() {},
};
</script>
<style scoped>
.hidescrollbar::-webkit-scrollbar {
  display: none;
}
</style>