<template>
  <div>
    <vx-card>
      <div class="w-full flex justify-between flex-wrap">
        <div class="w-1/2">
          <h4>نوع کاربر</h4>
          <p>{{ userInfo.created_at }}</p>
        </div>
        <div>
          <span
            style="
              border-radius: 5px;
              background-color: #30355d;
              color: #7367f0;
            "
          >
            {{ userInfo.post_paid ? "پس پرداخت" : "پیش پرداخت" }}
          </span>
        </div>
      </div>
      <vs-divider></vs-divider>
      <div class="my-1 font-bold">
        <p class="my-2">
          {{ ticketlist ? `${ticketlist} تیکت` : "تیکت ندارد" }}
        </p>
        <p class="my-2">
          {{ notelist ? `${notelist} یادداشت` : "یادداشت ندارد" }}
        </p>
        <p class="my-2">
          {{ notiflist ? `${notiflist} نوتیف` : "نوتیف ندارد" }}
        </p>
      </div>
      <vs-button
        v-if="userInfo.post_paid == 0"
        type="filled"
        @click="postpaidpopup = true"
        class="font-semibold w-full"
        >تبدیل به پس پرداخت</vs-button
      >
      <vs-button v-else @click="postpaidpopup = true"
        >تبدیل به پیش پرداخت</vs-button
      >
    </vx-card>
    <vs-popup
      class="email-compose"
      title="تبدیل به کاربر پس پرداخت"
      accept-text="Send"
      :active.sync="postpaidpopup"
    >
      <!-- Set postpaid user ====================================================================================================================================== -->
      <div v-if="userInfo.post_paid == 0">
        <vs-textarea
          class="my-10"
          style="direction: rtl"
          v-model="postpaiddata.description"
          label="توضیحات"
          height="80px"
        />
        <div class="flex">
          <vs-input
            label-placeholder="بازه زمانی (بر حسب ماه)"
            type="number"
            v-model="postpaiddata.pay_cycle"
          ></vs-input>
          <p class="text-warning my-6 mx-3">→ حداکثر 12 ماه</p>
        </div>
        <div class="flex">
          <vs-input
            class="my- w-64"
            label-placeholder="تا 100 میلیون تومان"
            type="number"
            v-model="postpaiddata.maximum_credit"
          ></vs-input>
          <p class="text-warning my-6 mx-3">
            → نهایت اعتبار قابل مصرف توسط این کاربر (تومان)
          </p>
        </div>
        <vs-button
          class="w-full"
          v-if="
            postpaiddata.pay_cycle > 0 &&
            postpaiddata.pay_cycle <= 12 &&
            postpaiddata.description &&
            postpaiddata.maximum_credit > 0 &&
            postpaiddata.maximum_credit <= 100000000
          "
          @click="
            postpaiddata.user_id = userID;
            setpostpaid();
            postpaidpopup = false;
          "
          >اعمال</vs-button
        >
      </div>
      <!-- Set postpaid user ====================================================================================================================================== -->
      <!-- Unset postpaid user ====================================================================================================================================== -->
      <div
        class="flex flex-wrap justify-center"
        v-else-if="userInfo.post_paid == 1"
      >
        <div class="w-full my-5 flex flex-wrap">
          <p>
            آیا مایلید کاربر
            <span class="mx-2 text-warning">{{ userInfo.name }}</span>
            را به پیش پرداخت تبدیل کنید
          </p>
        </div>
        <div class="w-1/2 flex justify-between">
          <div class="w-1/2 mx-2">
            <vs-button
              class="w-full"
              color="warning"
              type="gradient"
              @click="postpaidpopup = false"
              >خیر</vs-button
            >
          </div>
          <div class="w-1/2 mx-2">
            <vs-button
              class="w-full"
              color="danger"
              type="gradient"
              @click="
                unsetpostpaid();
                postpaidpopup = false;
              "
              >بله</vs-button
            >
          </div>
        </div>
      </div>
      <!-- Unset postpaid user ====================================================================================================================================== -->
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    userID: { required: true },
    userInfo: { required: true },
    ticketlist: { required: true },
    notelist: { required: true },
    notiflist: { required: true },
  },
  data() {
    return {
      postpaidpopup: false,
      postpaiddata: {},
    };
  },
  methods: {
    setpostpaid() {
      // console.log(this.postpaiddata);
      this.$http
        .post("/admin/billing/postpaid/set", this.postpaiddata)
        .then((result) => {
          // console.log(result);
          this.$vs.notify({ text: result.data.message, color: "success" });
          this.$emit("refreshdata");
        })
        .catch((err) => {
          // console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    unsetpostpaid() {
      // console.log(this.postpaiddata);
      this.$http
        .post("/admin/billing/postpaid/delete", { user_id: this.userID })
        .then((result) => {
          // console.log(result);
          this.$vs.notify({ text: result.data.message, color: "success" });
          this.$emit("refreshdata");
        })
        .catch((err) => {
          // console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
  },
};
</script>