<template>
  <div class="mt-6">
    <vx-card>
      <vs-row>
        <vs-col vs-w="6" vs-lg="6" vs-sm="7" vs-xs="12">
          <div>
            <!-- USER ACTIONS================================================================================= -->
            <div class="flex flex-wrap">
              <h3 class="font-semibold my-1">{{ userInfo.name }}</h3>
              <vs-button
                class="blink_me font-bold mx-2 w-16"
                v-if="userInfo.is_suspend"
                disabled
                color="danger"
                icon="warning"
                size="small"
                @click="unsuspenduserpopup = !unsuspenduserpopup"
                >مسدود</vs-button
              >
            </div>
            <!-- USER ACTIONS================================================================================= -->
            <!-- USER EMAIL ACTIONS================================================================================= -->
            <div class="flex my-3 mx-1">
              <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                <span
                  :class="
                    userInfo.email_status === 'تایید شده'
                      ? 'my-2 text-success font-semibold'
                      : 'my-2 text-danger font-semibold'
                  "
                >
                  {{ userInfo.email }}
                </span>
                <vs-dropdown-menu class="my-2">
                  <vs-button
                    class="w-full"
                    @click="verifyEmail"
                    :color="
                      userInfo.email_status === 'تایید شده'
                        ? 'danger'
                        : 'success'
                    "
                    type="gradient"
                    >{{
                      userInfo.email_status === "تایید شده"
                        ? "غیر فعال کردن ایمیل"
                        : "فعالسازی ایمیل"
                    }}</vs-button
                  >
                  <div class="my-5 w-full">
                    <vs-input
                      class="my-2 w-full"
                      placeholder="تغییر ایمیل کاربر"
                      type="text"
                      v-model="newEmail"
                    ></vs-input>
                    <vs-button
                      class="my-2 w-full"
                      type="filled"
                      @click="ChangeEmail(userID,newEmail);newEmail = '';"
                    >
                      Submit
                    </vs-button>
                  </div>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
            <!-- USER EMAIL ACTIONS================================================================================= -->
            <!-- USER PHONE NUMBER ACTIONS================================================================================= -->
            <div class="flex my-3 mx-1">
              <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                <p
                  style="letter-spacing: 2px"
                  :class="
                    userInfo.mobile_status === 'تایید شده'
                      ? 'text-success font-bold'
                      : 'text-danger font-bold'
                  "
                >
                  {{ userInfo.mobile }}
                </p>
                <vs-dropdown-menu class="my-2" >
                  <vs-button
                    class="w-full"
                    @click="verifyMobile"
                    :color="
                      userInfo.mobile_status === 'تایید شده'
                        ? 'danger'
                        : 'success'
                    "
                    type="gradient"
                    >{{
                      userInfo.mobile_status === "تایید شده"
                        ? "غیر فعال کردن شماره"
                        : "فعالسازی شماره"
                    }}</vs-button
                  >
                  <div class="my-5 w-full">
                    <vs-input
                      class="my-2 w-full"
                      placeholder="تغییر شماره کاربر"
                      type="number"
                      v-model="changephoneto"
                    ></vs-input>
                    <vs-button
                      class="my-2 w-full"
                      type="filled"
                      @click="
                        ChangeMobile({
                          user_id: userID,
                          mobile: changephoneto,
                        });
                        changephoneto = '';
                      "
                    >
                      Submit
                    </vs-button>
                  </div>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
            <!-- USER PHONE NUMBER ACTIONS================================================================================= -->
            <!--  -->
            <!-- Login As & Password -->
            <div class="flex">
              <vs-button class="mx-2 my-5 font-bold" @click="openUserAccount">
                ورود به پنل کاربر</vs-button
              >
              <div class="flex">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <vs-button
                    color="warning"
                    icon="vpn_key"
                    type="gradient"
                  ></vs-button>
                  <vs-dropdown-menu>
                    <div class="my-5">
                      <vs-input
                        class="my-2 w-full"
                        placeholder="تغییر پسورد کاربر"
                        v-model="changepassto"
                      ></vs-input>
                      <vs-button
                        @click="chnageUserPass"
                        class="my-2 w-full"
                        type="filled"
                        >Submit</vs-button
                      >
                    </div>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
          </div>
        </vs-col>
        <!-- SECOND PART==================================================================================================================================================================== -->
        <vs-col vs-w="5" vs-lg="5" vs-sm="3" vs-xs="12">
          <div>
            <div class="my-1 w-full flex justify-between">
              <div>
                <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                  <div class="flex cursor-pointer">
                    <vs-icon
                      :color="
                        this.userInfo.status === 'فعال' ? 'success' : 'danger'
                      "
                      :icon="
                        this.userInfo.status === 'فعال' ? 'check' : 'close'
                      "
                      class="my-1 mx-1"
                    ></vs-icon>
                    <span >وضعیت</span>
                  </div>
                  <vs-dropdown-menu>
                    <div>
                      <vs-button
                        :color="
                          this.userInfo.status === 'فعال' ? 'danger' : 'success'
                        "
                        type="gradient"
                        v-if="$acl.check('Editor')"
                        size="default"
                        @click="activeToggle"
                      >
                        {{
                          this.userInfo.status === "فعال" ? "غیر فعال" : "فعال"
                        }}سازی</vs-button
                      >
                    </div>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
              <p>{{ userInfo.status }}</p>
            </div>
            <div class="my-1 w-full flex justify-between">
              <div class="flex">
                <vs-icon icon="person" class="my-1 mx-1"></vs-icon>
                <a @click="ServicesVPCpopup = !ServicesVPCpopup">جزیره</a>
              </div>
              <p>{{ userInfo.status }}</p>
            </div>
            <div class="my-1 w-full flex justify-between">
              <div class="flex">
                <vs-icon icon="star" class="my-1 mx-1"></vs-icon>
                <a @click="ServicesViracloudpopup = !ServicesViracloudpopup"
                  >ویرا ابر</a
                >
              </div>
              <p>{{ userInfo.status }}</p>
            </div>
            <div class="my-1 w-full flex justify-between">
              <div class="flex">
                <vs-icon icon="flag" class="my-1 mx-1"></vs-icon>
                <a @click="ServicesHaiocloudpopup = !ServicesHaiocloudpopup"
                  >هایو ابر</a
                >
              </div>
              <p>{{ userInfo.status }}</p>
            </div>
            <div class="my-1 w-full flex justify-between">
              <div class="flex">
                <vs-icon icon="phone" class="my-1 mx-1"></vs-icon>
                <a @click="ServicesHostpopup = !ServicesHostpopup">هاست</a>
              </div>
              <p>{{ userInfo.status }}</p>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <!-- BUTTOM PART ===================================================================== -->
      <vs-row>
        <vs-col vs-w="9" vs-lg="9" vs-sm="12" vs-xs="12">
          <div class="flex">
            <div class="flex flex-wrap">
              <vs-button
                type="gradient"
                icon="attach_money"
                @click="balancepopup = !balancepopup"
              ></vs-button>
              <span class="mx-3">
                <p class="font-semibold">
                  {{ userInfo.balance | money }} تومان
                </p>
                <p>موجودی</p>
              </span>
            </div>
            <div class="flex flex-wrap gap-2">
              <vs-button
                color="success"
                type="gradient"
                icon="monetization_on"
                @click="scorepopup = !scorepopup"
              ></vs-button>
              <span class="mx-3">
                <p class="font-semibold">
                  {{ userInfo.point_balance | money }} تومان
                </p>
                <p>کیف پول</p>
              </span>

              <vs-button
                color="success"
                type="gradient"
                icon="show_chart"
                @click="getTransactions = true"
              >دریافت ریزمصرف</vs-button>
              <vs-button
                v-if="userTransactions"
                color="secondary"
                type="gradient"
                icon="cloud_download"
                target :href="userTransactions"
              >دانلود ریزمصرف</vs-button>

              <vs-popup
              title="دریافت ریز مصرف کاربر"
              :active.sync="getTransactions">
                <div class="flex justify-center">
                  <vs-input label-placeholder="از تاریخ" v-model="from" class="w-32 ml-2 py-0"/>
                  <vs-input label-placeholder="تا تاریخ" v-model="to" class="w-32 ml-2 py-0"/>
                </div>
                <vs-button type="gradient" color="secondary" class="w-full mt-4" @click="getUserTransactions(from, to), getTransactions = false">دریافت</vs-button>
              </vs-popup>
            </div>
          </div>
        </vs-col>
        <vs-col vs-w="3" vs-lg="3" vs-sm="12" vs-xs="12">
          <vs-button class="" @click="createLimit = !createLimit">ظرفیت</vs-button>
          <vs-button class="ml-2" @click="Servicespopup = !Servicespopup">لیست سرویس</vs-button>
          <!-- POPUP=============================================================================================================================================================== -->
          <vs-popup
            :fullscreen="false"
            title="ارسال نوتیفیکیشن"
            :active.sync="moreutilitypopup"
          >
            <vs-divider></vs-divider>
            <vs-divider></vs-divider>
          </vs-popup>
          <!-- balancepopup--------------------------------------------------------------- -->
          <!-- increase charge -->
          <vs-popup
            title="موجودی و شارژ"
            fullscreen
            :active.sync="balancepopup"
          >
            <vx-card>
              <vs-tabs alignment="fixed">
                <vs-tab v-if="adminUser || Is_Manager" label="افزایش شارژ">
                  <div class="mar justify-center">
                    <div class="flex">
                      <vs-input-number
                        min="0"
                        class="my-5 mb-1"
                        v-model="charge"
                        step="10000"
                      />
                    </div>
                    <vs-textarea
                      class="w-full"
                      label-placeholder="توصیحات شارژ(ضروری)"
                      v-model="chargeDescription"
                    ></vs-textarea>
                    <vs-button
                      v-if="chargeDescription"
                      class="mx-2 my-5 mb-1"
                      @click="addCharge"
                      >افزایش شارژ</vs-button
                    >
                  </div>
                </vs-tab>
                <vs-tab v-if="adminUser || Is_Manager" label="کاهش شارژ">
                  <!-- decrease charge ---------------------------------------------------------->
                  <div class="mar justify-center">
                    <div class="flex">
                      <vs-input-number
                        min="0"
                        class="my-5 mb-1"
                        v-model="reducecharge"
                        step="10000"
                      />
                    </div>
                    <vs-textarea
                      class="w-full"
                      label-placeholder="توصیحات کسر شارژ (ضروری)"
                      v-model="reducechargeDescription"
                    ></vs-textarea>
                    <p class="text-danger my-2">
                      تگ مربوط به کاهش شارژ را انتخاب کنید
                    </p>
                    <div class="border-solid border 5px border-primary">
                      <span
                        :key="indextr"
                        v-for="(tr, indextr) in reducetaglist"
                      >
                        <vs-radio
                          class="w-1/4"
                          v-model="reducetag"
                          :vs-value="tr.value"
                          >{{ tr.text }}</vs-radio
                        >
                      </span>
                    </div>
                    <vs-button
                      v-if="
                        reducetag && reducechargeDescription && reducecharge
                      "
                      @click="DecreaseCharge"
                      >کاهش شارژ</vs-button
                    >
                  </div>
                </vs-tab>
                <!-- increase list ------------------------------------------------------------>
                <vs-tab label="لیست افزایش شارژ">
                  <p slot="header" class="flex mar justify-center my-5"></p>
                  <div>
                    <!-- <vs-button class="my-5" @click="Getdepositlist()">refresh depositlist</vs-button> -->
                    <vs-table :data="depositlist">
                      <template slot="header"></template>
                      <template slot="thead">
                        <vs-th>مقدار افزایش</vs-th>
                        <vs-th>در تاریخ</vs-th>
                        <vs-th>توضیحات</vs-th>
                        <!-- <vs-th>Type</vs-th> -->
                        <vs-th>وضیت</vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                        >
                          <vs-td
                            :class="
                              data[indextr].amount >= 0
                                ? 'text-success'
                                : 'text-danger'
                            "
                            :data="data[indextr].amount"
                          >
                            {{ data[indextr].amount }}
                          </vs-td>
                          <vs-td :data="data[indextr].created_at">
                            {{ data[indextr].created_at }}
                          </vs-td>
                          <vs-td :data="data[indextr].meta">
                            {{ data[indextr].meta }}
                          </vs-td>
                          <!-- <vs-td :data="data[indextr].subject">
                {{ data[indextr].type }}
              </vs-td> -->
                          <vs-td :data="data[indextr].confirmed"> </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                    <!-- <p>{{depositlist}}</p> ------------------------------------------------------------>
                  </div>
                </vs-tab>
              </vs-tabs>
            </vx-card>
          </vs-popup>
          <!-- /balancepopup--------------------------------------------------------------- -->
          <!-- scorepopup--------------------------------------------------------------- -->
          <vs-popup fullscreen title="کیف پول هایو" :active.sync="scorepopup">
            <vs-tabs alignment="fixed">
              <vs-tab label="افزایش امتیاز">
                <div>
                  <div>
                    <vs-textarea
                      v-model="giftscoreDescription"
                      placeholder="توضیحات"
                    ></vs-textarea>
                    <vs-input
                      class="w-1/2 my-5"
                      type="number"
                      v-model="giftscoreAmount"
                      placeholder="مقدار"
                    ></vs-input>
                  </div>
                  <vs-button
                    color="warning"
                    class="my-5"
                    @click="GiftScore()"
                    type="border"
                    >افزایش</vs-button
                  >
                </div>
              </vs-tab>
              <vs-tab label="کاهش امتیاز">
                <div>
                  <div>
                    <vs-textarea
                      v-model="reducetscoreDescription"
                      placeholder="توضیحات"
                    ></vs-textarea>
                    <vs-input
                      class="w-1/2 my-5"
                      type="number"
                      v-model="reducescoreAmount"
                      placeholder="مقدار"
                    ></vs-input>
                  </div>
                  <div class="border-solid border 5px border-primary my-5">
                    <span :key="indextr" v-for="(tr, indextr) in reducetaglist">
                      <vs-radio
                        class="w-1/4"
                        v-model="reducescoretag"
                        :vs-value="tr.value"
                        >{{ tr.text }}</vs-radio
                      >
                    </span>
                  </div>
                  <vs-button
                    color="warning"
                    class="my-5"
                    @click="reducescore()"
                    type="border"
                    >کاهش</vs-button
                  >
                </div>
              </vs-tab>
              <vs-tab label="هایو پوینت">
                <div>
                  <vs-table :data="scoretransaction">
                    <template slot="thead">
                      <vs-th>مقدار افزایش</vs-th>
                      <vs-th>در تاریخ</vs-th>
                      <vs-th>توضیحات</vs-th>
                      <vs-th>وضیت</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td
                          :class="
                            data[indextr].amount >= 0
                              ? 'text-success'
                              : 'text-danger'
                          "
                          >{{ data[indextr].amount }}</vs-td
                        >
                        <vs-td>{{ data[indextr].created_at }}</vs-td>
                        <vs-td>{{ data[indextr].meta.description }}</vs-td>
                        <vs-td>{{
                          data[indextr].confirmed ? "تایید شده" : "تایید نشده"
                        }}</vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </vs-tab>
            </vs-tabs>
          </vs-popup>
          <!-- /scorepopup--------------------------------------------------------------- -->
          <vs-popup title="کیف پول هایو" :active.sync="scorepopup">
            <div>
              <vs-textarea
                v-model="giftscoreDescription"
                placeholder="توضیحات"
              ></vs-textarea>
              <vs-input
                class="w-1/2 my-5"
                type="number"
                v-model="giftscoreAmount"
                placeholder="مقدار"
              ></vs-input>
            </div>
            <vs-button
              color="warning"
              class="my-5"
              @click="GiftScore()"
              type="border"
              >اعمال</vs-button
            >
          </vs-popup>
          <!-- SERVICES=============================================================================================================================================================== -->
          <!-- ALL USER SERVICES -->
          <vs-popup fullscreen :active.sync="Servicespopup">
            <UserServices></UserServices>
          </vs-popup>
          <vs-popup  :active.sync="createLimit" title="افزایش ظرفیت ساخت سرور">
            <div class="flex justify-center gap-3">
              <vs-input class="inputx w-16" placeholder="ظرفیت " v-model="newCreateLimit"/>
              <vs-button grediant color="secondary" @click="changeCreateLimit(newCreateLimit)">اعمال</vs-button>
            </div>
          </vs-popup>
          <!-- ALL USER SERVICES -->
          <!-- HAIO CLOUD POPUP -->
          <vs-popup
            title="VPCs list"
            fullscreen
            :active.sync="ServicesVPCpopup"
          >
            <vx-card>
              <VPCs :userId="userID"></VPCs>
            </vx-card>
          </vs-popup>
          <!-- HAIO CLOUD POPUP -->
          <!-- HOSTS POPUP -->
          <vs-popup
            title="Viraclouds list"
            fullscreen
            :active.sync="ServicesViracloudpopup"
          >
            <vx-card>
              <Viraclouds :userId="userID"></Viraclouds>
            </vx-card>
          </vs-popup>
          <!-- HOSTS POPUP -->
          <!-- VIRACLOUD POPUP -->
          <vs-popup
            title="Haio cloud list"
            fullscreen
            :active.sync="ServicesHaiocloudpopup"
          >
            <vx-card>
              <Haioclouds :userID="userID"></Haioclouds>
            </vx-card>
          </vs-popup>
          <vs-popup
            title="Hosts list"
            fullscreen
            :active.sync="ServicesHostpopup"
          >
            <vx-card>
              <Hosts :userId="userID"></Hosts>
            </vx-card>
          </vs-popup>
          <vs-popup
            title="فعال سازی دستی کاربر"
            :active.sync="unsuspenduserpopup"
          >
            <div class="flex">
              <div class="w-full flex flex wrap">
                <p>آیا میخواهید کاربر مسدود را فعال کنید</p>
              </div>
              <div class="flex flex-wrap justify-around">
                <vs-button
                  class="w-full mx-1"
                  color="success"
                  @click="
                    unsuspendUser();
                    unsuspenduserpopup = false;
                  "
                  >بله</vs-button
                >
                <vs-button class="w-full mx-1" color="" type="border"
                  >خیر</vs-button
                >
              </div>
            </div>
          </vs-popup>
          <!-- VIRACLOUD POPUP -->
          <!-- VPCs -->
          <!-- VPCs -->
          <!-- //SERVICES=============================================================================================================================================================== -->
          <!-- /POPUP=============================================================================================================================================================== -->
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import UserServices from "../../UserServices/UserServices.vue";
import Haioclouds from "../../UserServices/serviceComponents/Haiocloud.vue";
import Hosts from "../../UserServices/serviceComponents/Host.vue";
import Viraclouds from "../../UserServices/serviceComponents/Viracloud.vue";
import VPCs from "../../UserServices/serviceComponents/VPC.vue";
import moment from 'moment-jalaali'

export default {
  props: {
    adminUser: { type: Boolean, required: false },
    userID: { required: true },
    userInfo: { required: true },
  },
  components: {
    UserServices,
    Haioclouds,
    Hosts,
    Viraclouds,
    VPCs,
  },
  watch: {
    reducecharge() {
      console.log(this.reducecharge);
    },
  },
  data() {
    return {
      VPClist: [],
      //===Phone & password actions
      changephoneto: "",
      newEmail:"",
      changepassto: "",
      //===Phone & password actions

      //===POPUPS
      moreutilitypopup: false,
      emailpopup: false,
      balancepopup: false,
      scorepopup: false,
      getTransactions: false,
      unsuspenduserpopup: false,
      //--SERVICES
      Servicespopup: false,
      createLimit: false,
      newCreateLimit: '',
      ServicesHaiocloudpopup: false,
      ServicesHostpopup: false,
      ServicesViracloudpopup: false,
      ServicesVPCpopup: false,
      //===POPUPS
      //---
      //---
      //---
      //==BALANCE==
      //--INCREASE
      charge: 0,
      chargeDescription: "",
      depositlist: [],
      //--REDUCE
      reducecharge: 0,
      reducechargeDescription: "",
      reducetag: null,
      reducetaglist: [
        { text: "Devops", value: "devops" },
        { text: "Sysadmin", value: "sysadmin" },
        { text: "Cloudmigration", value: "cloudmigration" },
        { text: "Vira", value: "vira" },
        { text: "Containo", value: "containo" },
        { text: "Didban", value: "didban" },
        { text: "Mail", value: "mail" },
        { text: "Haiocloud", value: "haiocloud" },
        { text: "Vps", value: "vps" },
      ],
      //==SCORE==
      scoretransaction: [],
      // ---increase
      giftscoreDescription: "",
      giftscoreAmount: "",
       //---decrease---
      reducetscoreDescription: "",
      reducescoreAmount: "",
      reducescoretag: "",
      //==SCORE==
      //==BALANCE==
      year: '',
      month: '',
      day: '',
      from: moment().format('jYYYY-jM-jD'),
      to: moment().format('jYYYY-jM-jD'),
      userTransactions:''
    };
  },
  methods: {
    logger(data) {
      console.log("******************", data, "******************");
    },
    openUserAccount() {
      this.$http
        .post("/admin/user/login/using", { id: this.userID })
        .then((result) => {
          const token = result.data.params.token;
          window.open(`https://console.haio.ir/pages/login?acc=${token}`);
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    // MANAGE POPUP METHODS============================================================================================================================================================================================
    verifyEmail() {
      const verify = this.userInfo.email_status === "تایید شده" ? "2" : "1";
      const data = {
        id: this.userID,
        verify,
      };

      this.$http
        .put("/admin/user/verifyEmail", data)
        .then((res) => {
          this.$vs.notify({ title: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        })
        .finally(() => {
          this.$emit("refreshdata");
          this.$emit("refreshdata");
        });
    },
    ChangeEmail(user,newEmail) {
      this.$http
        .post("/admin/user/change/email", {new_email: newEmail, user_id: user})
        .then((res) => {
          this.$vs.notify({ text: res.data.message, color: "success" });
          if (res.data.code === 200) {
            console.log(newEmail)
            this.userInfo.email = newEmail;
          }
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    verifyMobile() {
      const verify = this.userInfo.mobile_status === "تایید شده" ? "2" : "1";
      const data = {
        id: this.userID,
        verify,
      };
      console.log(data);
      this.$http
        .put("/admin/user/verifyMobile", data)
        .then((res) => {
          this.$vs.notify({ title: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        })
        .finally(() => {
          this.$emit("refreshdata");
        });
    },
    ChangeMobile(data) {
      // console.log(data);
      this.$http
        .put("admin/user/update", data)
        .then((res) => {
          this.$vs.notify({ text: res.data.message, color: "success" });
          if (res.data.message === "اطلاعات کاربر با موفقیت ویرایش شد") {
            this.userInfo.mobile = data.mobile;
          }
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
      // ChangeMobile({ user_id: userID, mobile: null })
    },
    chnageUserPass() {
      const data = { id: this.userID, password: this.changepassto };
      this.$http
        .put("/admin/user/changePassword", data)
        .then((res) => {
          this.$vs.notify({ title: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({
            title: err.response.data.message,
            color: "danger",
          });
        })
        .finally(() => {
          this.$emit("refreshdata");
          this.password = "";
        });
    },
    activeToggle() {
      const status = this.userInfo.status === "فعال" ? "2" : "1";
      const data = {
        id: this.userID,
        status,
        block_reason: this.reason,
      };
      // console.log(data);
      this.$http
        .put("/admin/user/status", data)
        .then((res) => {
          this.$vs.notify({ title: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({
            title: err.response.data.message,
            color: "danger",
          });
          console.log(err);
        })
        .finally(() => {
          this.$emit("refreshdata");
        });
    },
    unsuspendUser() {
      this.$http
        .post("/admin/user/suspend", { id: this.userID })
        .then((res) => {
          this.$vs.notify({ title: res.data.message, color: "success" });
          console.log(res);
        })
        .catch((err) => {
          this.$vs.notify({
            title: err.response.data.message,
            color: "danger",
          });
          console.log(err);
        });
    },
    // MANAGE POPUP METHODS============================================================================================================================================================================================
    // SCORE POPUP METHODS============================================================================================================================================================================================
    GiftScore() {
      const data = {
        user_id: this.userID,
        amount: this.giftscoreAmount,
        description: this.giftscoreDescription,
      };
      this.$http
        .post(`admin/billing/direct/point/charge`, data)
        .then((res) => {
          // console.log(res);
          this.$vs.notify({ text: res.data.message, color: "success" });
          this.$emit("refreshdata");
        })
        .catch((err) => {
          // console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    reducescore() {
      const data = {
        user_id: this.userID,
        amount: this.reducescoreAmount,
        description: this.reducetscoreDescription,
        tag: this.reducescoretag,
      };
      this.$http
        .post(`admin/billing/reduce/point`, data)
        .then((res) => {
          // console.log(res);
          this.$vs.notify({ text: res.data.message, color: "success" });
          this.$emit("refreshdata");
        })
        .catch((err) => {
          // console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    // SCORE POPUP METHODS============================================================================================================================================================================================
    // BALANCE POPUP METHODS============================================================================================================================================================================================
    addCharge() {
      const data = {
        user_id: this.userID,
        amount: this.charge,
        description: this.chargeDescription,
      };
      this.$http
        .post("/admin/billing/direct/charge", data)
        .then((res) => {
          this.$vs.notify({ title: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ title: err.response.data.message });
        })
        .finally(() => {
          this.$emit("refreshdata");
          this.Getdepositlist();
        });
    },
    DecreaseCharge() {
      const data = {
        user_id: this.userID,
        amount: this.reducecharge,
        description: this.reducechargeDescription,
        tag: this.reducetag,
      };
      // console.log(data);
      this.$http
        .post("/admin/billing/reduce/charge", data)
        .then((res) => {
          this.$vs.notify({ title: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ title: err.response.data.message });
        })
        .finally(() => {
          this.$emit("refreshdata");
          this.Getdepositlist();
        });
    },
    Getdepositlist() {
      // console.log(this.userID);
      this.depositlist = [];
      this.$http
        .get(`/admin/billing/pay/deposit/transaction?user_id=${this.userID}`)
        .then((res) => {
          // this.$vs.notify({ title: res.data.message });
          this.depositlist = res.data.params.data;
          // console.log(this.depositlist);
        })
        .catch((err) => {
          this.$vs.notify({ title: err.response.data.message });
        })
        .finally(() => {
          this.$emit("refreshdata");
        });
    },
    Getpointdata() {
      this.scoretransaction = [];
      this.$http
        .post("admin/billing/haiopoint/list", { user_id: this.userID })
        .then((res) => {
          this.scoretransaction = res.data.params.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refreshReduceCharge() {
      this.$http
        .post("admin/billing/wallet/refresh", { user_id: this.userID })
        .then((res) => {
          this.$vs.notify({
            color: "success",
            text: `${res.data.message}`,
          });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    // BALANCE POPUP METHODS============================================================================================================================================================================================
    changeCreateLimit(newLimit) {
      this.$http
      .post("/admin/cloud/limit/user/store", { user_id: this.userID,
        total_vm: newLimit,
        total_vpc: 5,
        total_volume: 2,
        total_snapshot: 2,


      })
      .then((res) => {
        this.$vs.notify({
          color: "success",
          text: `${res.data.message}`,
        });
      })
      .catch((err)=> {
        this.$vs.notify({text: err.response.data.message, color: "danger"});
      });
    },
    getUserTransactions(from, to) {
      this.$http.post('/admin/excel/transaction/date', {from_date: from, to_date: to, user_id: this.userID})
      .then((res) => {
        this.userTransactions = res.data.replace('http://', 'https://');
      })
    },

  },
  computed: {
    Is_Manager() {
      if (
        this.$store.state.AppActiveUser.email ===
          "sajadrasti@haiocloud.com" || "fatemeazdiiii2130@gmail.com"
      ) {
        return true
      } else {
        return false
      }

    },
    is_Financial() {
        if (
        this.$store.state.AppActiveUser.email ===
          "sajadrasti@haiocloud.com" || "h.safarzadeh@haio.ir" || "m.hooshmand@haiocloud.com" || "fatemeazdiiii2130@gmail.com"
      ) {
        return true;
      } else {
        return false;
      }
    }

  },
  mounted() {
    this.Getdepositlist();
    this.Getpointdata();
  },

};
</script>
<style scoped>
.blink_me {
  animation: blinker 2s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
.hidescrollbar::-webkit-scrollbar {
  display: none;
}
</style>
