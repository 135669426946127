<template>
  <div>
    <vs-table
      @dblSelection="doubleSelection"
      v-model="selected"
      :data="VPClist"
    >
      <template slot="thead">
        <vs-th> VPC ID </vs-th>
        <vs-th> User ID </vs-th>
        <vs-th> Is suspend </vs-th>
        <vs-th> Suspended at </vs-th>
        <vs-th> Created at </vs-th>
        <vs-th> Total public IP </vs-th>
        <vs-th> Total VM </vs-th>
        <vs-th> Vlan ID </vs-th>
        <vs-th> VPC hostname </vs-th>
        <vs-th> VPC name </vs-th>
        <vs-th> VPC number </vs-th>
        <vs-th> VPC status </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].VPC_ID">
            {{ data[indextr].id }}
          </vs-td>
          <vs-td :data="data[indextr].User_ID">
            {{ data[indextr].user_id }}
          </vs-td>
          <vs-td
            :class="data[indextr].is_suspend ? 'text-danger' : 'text-success'"
            :data="data[indextr].Is_suspend"
          >
            {{ data[indextr].is_suspend ? "Suspended" : "Normal" }}
          </vs-td>
          <vs-td :data="data[indextr].Suspended_at">
            {{
              data[indextr].suspended_at ? data[indextr].suspended_at : "null"
            }}
          </vs-td>
          <vs-td :data="data[indextr].Created_at">
            {{ data[indextr].created_at }}
          </vs-td>
          <vs-td :data="data[indextr].Total_public_IP">
            {{ data[indextr].total_public_ip }}
          </vs-td>
          <vs-td :data="data[indextr].Total_VM">
            {{ data[indextr].total_vm }}
          </vs-td>
          <vs-td :data="data[indextr].Vlan_ID">
            {{ data[indextr].vlan_id }}
          </vs-td>
          <vs-td :data="data[indextr].VPC_hostname">
            {{
              data[indextr].vpc_hostname
                ? `Name:${data[indextr].vpc_hostname}`
                : "null"
            }}
          </vs-td>
          <vs-td :data="data[indextr].VPC_name">
            {{ data[indextr].vpc_name }}
          </vs-td>
          <vs-td :data="data[indextr].VPC_number">
            {{ data[indextr].vpc_number }}
          </vs-td>
          <vs-td :data="data[indextr].VPC_status">
            {{ data[indextr].vpc_status ? "Active" : "Inactive" }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      class="my-5"
      :total="VPC_LP"
      v-model="VPC_PN"
    ></vs-pagination>
  </div>
</template>
<script>
export default {
  props: {
    userId: { required: true },
  },
  data() {
    return {
      VPClist: [],
      selected: "",
      //==pagination==
      VPC_LP: 1, // Cont of pages
      VPC_PN: 1, // Page number
      //==pagination==
    };
  },
  watch: {
    selected() {
      this.$emit("selected", this.selected);
    },
    VPC_PN() {
      this.getVPClist();
    },
  },
  methods: {
    getVPClist() {
      this.$http
        .post("/admin/vira/cloud/vpc/list", {
          user_id: this.userId || this.$route.query.id,
          page: this.VPC_PN,
        })
        .then((res) => {
          this.VPC_LP = res.data.params.last_page;
          this.VPClist = res.data.params.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    doubleSelection() {
      this.$router.push(`/user?id=${this.selected.user_id}`);
    },
  },
  created() {
    this.getVPClist();
  },
};
</script>