<template>
  <div>
    <vs-row>
      <vs-col>
        <vs-tabs alignment="fixed" class="w-full">

          <!-- =========  VIRA CLOUD TABLE  ========================================================================================================================================================================================================================== -->
          <vs-tab label="لیست سرور ها">
            <vx-card>
              <ViraCloud
                :userId="this.$route.params.id || this.userId"
                @selected="EventSelected"
              ></ViraCloud>
            </vx-card>
          </vs-tab>
        </vs-tabs>
        <!-- <vs-collapse>
          <vs-collapse-item not-arrow>
            <vs-button color="primary" class="w-full" slot="header" icon="expand_more"></vs-button> -->
        <vs-divider></vs-divider>
        

       
      
        <!-- </vs-collapse-item>
        </vs-collapse> -->
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
//===SERVICES===
import ViraCloud from "./serviceComponents/Viracloud.vue";
//===SERVICES===
export default {
  components: {
    VueJsonPretty,
    ViraCloud,
  },
  data: () => ({
    userId: "",
    selected: [],
  }),
  methods: {
    EventSelected(value) {
      this.selected = value;
    },
  },
};
</script>
